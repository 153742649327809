<template>
    <tr class="d-flex flex-column flex-md-row px-lg-3 mb-3 mb-md-0 justify-content-between car-div-color">
        <td class="d-flex flex-column  flex-md-row px-0 pb-0 py-md-3 border-0 align-items-center flex-grow-1">
            <!-- photo -->
            <div class="image-wrapper">
                <a :href="auction.route">
                    <img :src="auction.image_url" alt="photo" class="img-cover" width="150px" height="100px">
                </a>
            </div>
            <!-- informations -->
            <div class="d-flex flex-column align-items-center align-items-md-start">
                <div class="px-3 pt-2 pt-md-0 d-flex flex-wrap align-items-center justify-content-center text-center">
                    <a class="text-dark" :href="auction.route">
                        <h3 class="font-weight-bolder text-uppercase mb-0">{{auction.name}}</h3>

                    </a>
                    <button class="btn p-0 m-0 button-fw">
                        <i class="far fa-trash-alt fa-lg text-danger ml-2 pt-1 pb-1" v-on:click="deleteFavourite"></i>
                    </button>
                </div>
                <div class="px-3 d-flex flex-row flex-md-column align-items-start justify-content-center">
                    <span class="text-muted pr-0 pr-md-2">{{auction.country.name}}, {{auction.city}}</span>
                </div>
            </div>
        </td>
        <!-- time/price -->
        <td class="d-flex border-0 justify-content-center flex-shrink-0">
            <div class="d-flex flex-column align-items-center align-items-md-end justify-content-center">
                <div v-if="auction.status != 'ended'" class="d-flex justify-content-end">
                    <i class="far fa-clock pt-1 pr-1"></i>
                    <span class="font-weight-bolder">
                        <timer
                            :auction-end-props="auction.end" :auction-status-props="auction.status"
                        />
                    </span>
                </div>
                <div class="d-flex justify-content-end  px-2 pl-2 pr-md-0 py-md-2">
                    <h5 class="text-orange font-weight-bolder mb-0"><money :currentBidProps="auction.current_bid" :currency="auction.currency"></money></h5>
                </div>
                <div>
                    <span class="font-weight-bolder">{{auction.number_of_bids}} {{ __('auction.bids') }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                    <div class="pr-2 pb-1">
                        <span class="text-muted font-weight-bolder text-uppercase">{{ __('auction.notify') }}</span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center pt-2">
                    <label class="switch">
                        <input id="on" type="checkbox" v-model="notify" v-on:click="notifyChange">
                        <div class="slider postion-relative rounded"></div>
                    </label>
                    </div>
                </div>
            </div>
            <delete-modal v-if="isDelete" :route="this.auction.fav_route" :type="typeTranslation" @closed="isDelete = false" @deleted="favouriteDeleted"></delete-modal>
        </td>
    </tr>
</template>

<script>
import Timer from './Timer.vue';
import Money from './Money.vue';
import DeleteModal from './DeleteModal.vue';
export default {
    components: {
        Timer,
        Money,
        DeleteModal
    },
    props: {
        auction: {
            required: true
        },
    },
    created: function () {
        this.notify = this.auction.notify;
    },
    data() {
        return {
            timeLeft: null,
            notify: null,
            isDelete: false,
            typeTranslation: this.__('favorites.favourite')
        };
    },
    methods: {
        deleteFavourite() {
            this.isDelete = true;
        },
        favouriteDeleted() {
            this.$emit('favourite-deleted', this.auction.id);
            Vue.$toast.open({
                    message: this.__("auction.removed_to_favorites"),
                    type: 'warning',
                });
        },
        notifyChange() {
            axios.patch(this.auction.notifications_route)
            .then(res => {

            });
        },
    },
};

</script>
