import { render, staticRenderFns } from "./AddPhotos.vue?vue&type=template&id=46e9d414&scoped=true&"
import script from "./AddPhotos.vue?vue&type=script&lang=js&"
export * from "./AddPhotos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46e9d414",
  null
  
)

export default component.exports